@charset "utf-8";

// Our variables
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$base-font-size:   18px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #006cd1;

$grey-color:       #757575;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    900px;

$on-palm:          600px;
$on-laptop:        900px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base";
@import "layout";
@import "syntax-highlighting";

.about-image {
    float: left;
    margin-right: 30px;
}

.rss-subscribe {
    float: right;
}

.post-categories {
    font-size: $base-font-size;
}

.post-content h2 {
    position: relative;
    border-bottom: 1px solid #ccc;

    a {
        font-size: 20px;
        position: absolute;
        right: 0;
        bottom: 9px;
        visibility: hidden;
        color: #000;
    }
    &:hover a, a:hover {
        visibility: visible;
        text-decoration: none;
    }
}

.title-letter {
    margin-bottom: 5px;
    margin-right: -1px;
}

.pagination {
    text-align: center;

    .page-number {
        display: inline-block;
        padding: 0 50px;
    }
}

.home .post-list > li {
    padding-bottom: $spacing-unit;
    border-bottom: 1px solid #ccc;
}

// Fancy avatar stuff taken directly from https://css-tricks.com/a-fancy-hover-effect-for-your-avatar/
.avatar-table {
    margin: 0 auto;

    img {
        --s: 200px; /* image size */
        --b: 6px; /* border thickness */
        --c: #255b98; /* border color */
        --cb: #e9ecef; /* background color */
        --f: 1; /* initial scale */

        width: var(--s);
        aspect-ratio: 1;
        padding-top: calc(var(--s)/5);
        cursor: pointer;
        border-radius: 0 0 999px 999px;
        --_g: 50%/calc(100%/var(--f)) 100% no-repeat content-box;
        --_o: calc((1/var(--f) - 1)*var(--s)/2 - var(--b));
        outline: var(--b) solid var(--c);
        outline-offset: var(--_o);
        background:
            radial-gradient(
            circle closest-side,
            var(--cb) calc(99% - var(--b)),var(--c) calc(100% - var(--b)) 99%,#0000
            ) var(--_g);
        -webkit-mask:
            linear-gradient(#000 0 0) no-repeat
            50% calc(1px - var(--_o)) / calc(100%/var(--f) - 2*var(--b) - 2px) 50%,
            radial-gradient(circle closest-side,#000 99%,#0000) var(--_g);
        transform: scale(var(--f));
        transition: .5s;
    }
    img:hover {
        --f: 1.2; /* hover scale */
    }
}
